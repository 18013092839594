import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DataContext } from 'context/data/DataContextProvider';
import { Footer } from 'bsd-global-nav-design-ui';
import { matchFlags } from 'utils/displayRules';
import { getAppName } from 'utils/script';
import { getLogoUrl } from 'utils/util';
import { FooterModifier, L1Data } from 'utils/shapes';
import { filterHeaderOnlyLinks, filterFooterLinks } from 'utils/footerUtils';

export default function GlobalFooter({
  flowFooterData,
  footerL1Data,
  footerL2Data,
  footerModifier,
  hubData,
}) {
  const { featureFlags, footer, isAuthenticated, scriptData } =
    useContext(DataContext);

  if (!footerL1Data) return null;

  const { comcastLogoLink: logoLinks, level1_left: l1Left } = footerL1Data;

  const logoUrl = getLogoUrl(
    getAppName(),
    isAuthenticated,
    logoLinks,
    scriptData
  );

  const mergeEnterpriseData = () => {
    const enterpriseL2Data = filterHeaderOnlyLinks(
      (footerL2Data ?? l1Left)?.find((x) => x.DisplayText === 'Solutions')
        ?.children
    );
    const enterpriseStaticLinks = filterFooterLinks(
      footer.main_footer.staticLinks.enterprise,
      { isLoggedIn: isAuthenticated, isEnterprise: true },
      ['isLoggedIn', 'isEnterprise']
    );
    return {
      enterprise: [...enterpriseL2Data, ...enterpriseStaticLinks],
    };
  };

  const mergeShopData = () => {
    const staticShopData =
      footer.main_footer.staticLinks.smallBusiness.find(
        (item) => item.DisplayText === 'Small Business'
      )?.children ?? [];

    const shopData =
      (footerL2Data ?? l1Left)?.find(
        (item) => item.DisplayText === 'Small Business'
      ) ?? {};

    const finalShopData = {
      ...shopData,
      children: [...(shopData.children ?? []), ...staticShopData],
    };

    return [
      finalShopData,
      ...footer.main_footer.staticLinks.smallBusiness.filter(
        (item) => item.DisplayText !== 'Small Business'
      ),
    ];
  };

  const mergeSupportData = (filterStaticLinksOnly) => {
    const staticSupportData = footer.main_footer.staticLinks.smallBusiness.find(
      (item) => item.DisplayText === 'Support'
    );

    if (filterStaticLinksOnly) {
      const filteredData = filterFooterLinks(
        staticSupportData.children,
        { isLoggedIn: false, isEnterprise: footerModifier === 'enterprise' },
        [],
        featureFlags
      );

      return {
        ...staticSupportData,
        children: [...filteredData],
      };
    }
    const supportData = l1Left.find((item) => item.DisplayText === 'Support');

    const staticSupportChildren = staticSupportData?.children?.filter(
      (item) => item.DisplayText !== 'Comcast Business Support'
    );

    return {
      ...supportData,
      children: [...supportData.children, ...staticSupportChildren],
    };
  };

  const mergeSubscribedServicesData = () => {
    const subscribedServicesData =
      hubData?.hub_Navigation?.find(
        (item) => item.DisplayText === 'Subscribed Services'
      )?.children ?? [];

    const newSubscribedServicesData = subscribedServicesData
      .map((item) => {
        if (item?.DisplayLink) {
          return item?.children?.length ? [item, ...item?.children] : item;
        }
        return item?.children?.length ? [...item?.children] : item;
      })
      .flat();

    const accountManagementData =
      hubData?.hub_Navigation?.find(
        (item) => item.DisplayText === 'Account Management'
      )?.children ?? [];

    return [newSubscribedServicesData, accountManagementData];
  };

  const buildFooterData = () => {
    if (footerModifier === 'enterprise') {
      return mergeEnterpriseData();
    }

    const smallBusiness = mergeShopData();

    if (!isAuthenticated) {
      const supportData = mergeSupportData(true);

      return {
        smallBusiness: smallBusiness.map((item) => {
          if (item.DisplayText === 'Support') {
            // TEMPORARY FUNCTIONALITY - to support new H&S url behind flag
            if (
              matchFlags(item, featureFlags) &&
              item?.AlternateLinks?.length
            ) {
              item.DisplayLink = item.AlternateLinks[0];
            }
            // END TEMPORARY
            return supportData;
          }
          return item;
        }),
      };
    }

    const finalSupportData = mergeSupportData();

    const [subscribedServicesData, accountManagementData] =
      mergeSubscribedServicesData();

    return {
      smallBusiness: smallBusiness.map((item) => {
        if (item.DisplayText === 'Support') {
          return finalSupportData;
        }
        if (item.DisplayText === 'My Account') {
          return {
            ...item,
            children: [...subscribedServicesData, ...accountManagementData],
          };
        }
        return item;
      }),
    };
  };

  const {
    flow_Footer: { flowIcons: icons },
  } = footer ?? [];

  const footerData = {
    ...footer,
    flow_Footer: { flowLinks: flowFooterData, flowIcons: icons },
    main_footer: {
      staticLinks: buildFooterData(),
    },
  };

  return (
    <Footer data={footerData} logoLink={logoUrl} modifier={footerModifier} />
  );
}

GlobalFooter.propTypes = {
  flowFooterData: PropTypes.array,
  footerL1Data: L1Data,
  footerL2Data: PropTypes.array,
  footerModifier: FooterModifier,
  hubData: PropTypes.object,
};
