import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { fidoV2 as fido } from '@bsd/ui-utils';
import { SemanticAttributes, spanRequest } from '@bsd/observability';
import { ApiSA as NavServiceSa } from '@bsd/service-agent-selfservice-service-navigation-api';

import { getConfig } from 'config';
import { getComAuthCore, getScriptDataset } from 'utils/script';
import { dispatchEvent } from 'utils/events';
import {
  getCurrentAccount,
  getTrackingIdHeader,
  getTracerName,
} from 'utils/util';
import { trackEvent, events } from 'utils/tracking';
import {
  API_CALL_TYPE,
  API_ERROR_MESSAGE,
  APP_TYPES,
  CLOSED_ACCOUNT_ROLES,
} from 'utils/constants';

export const AccountManagerContext = createContext();
export const AccountManagerProvider = ({ children }) => {
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [currentAccountIsOrion, setCurrentAccountIsOrion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasComAuthCore = Boolean(getComAuthCore());

  const handleSetCurrentAccount = (userData) => {
    const { accounts, defaultAccountAuthGuid, activeAccounts } = userData ?? {};

    if (accounts?.length && userData?.selectedAccount) {
      const selectedId =
        userData.selectedAccount.authGuid || userData.selectedAccount.orderId;
      const { isClosed, isOrion, roles } = getCurrentAccount(
        accounts,
        selectedId
      );

      const hasClosedAccountAccess = roles?.find(
        (role) => CLOSED_ACCOUNT_ROLES[role]
      );

      // Only switch accounts when the currentAccount is closed, have no access to closed accounts,
      // while having active accounts to switch to and the default account is not closed
      if (
        !isClosed ||
        hasClosedAccountAccess ||
        !activeAccounts?.length ||
        !activeAccounts.find((x) => x.authGuid === defaultAccountAuthGuid)
      ) {
        setCurrentAccountIsOrion(isOrion);
        setCurrentAccountId(selectedId);
        return;
      }

      // Edge case when the user bookmarks a resource url with an account that is now closed,
      // but does not have proper roles to view closed accounts, switch to default
      const defaultAccount = getCurrentAccount(
        accounts,
        defaultAccountAuthGuid
      );

      handleAccountSwitch(defaultAccount);

      setCurrentAccountId(defaultAccountAuthGuid);
      setCurrentAccountIsOrion(!!defaultAccount?.isOrion);
    }
  };

  const sendAccountSwitchRequest = async (account) => {
    const { authGuid, accountNumber, orderId, orderReferenceNumber } = account;

    const data = {
      authGuid,
      ...(orderId && { orderId }),
      ...(orderReferenceNumber && { orderReferenceNumber }),
    };
    const { featureFlags, navSaConfig } = getConfig();
    const { isBcpSwitchAccountCallDisabled } = featureFlags;
    const { navigationSaUrl } = navSaConfig;
    const { ['tracking-id']: trackingId } = getTrackingIdHeader();
    const accountSelectionOptions = {
      headers: {
        authorization: getScriptDataset().authToken,
        ['content-type']: 'application/json',
        ...(hasComAuthCore && { 'com-auth-core': getComAuthCore() }),
        ...getTrackingIdHeader(),
      },
      withCredentials: hasComAuthCore ? false : true,
    };

    const navSA = new NavServiceSa(navigationSaUrl);
    const saSwitchCall = spanRequest({
      method: 'post',
      requestFn: () =>
        navSA.accountSelection(trackingId, data, accountSelectionOptions),
      tracerName: getTracerName('saSwitchCall'),
      attributes: {
        [SemanticAttributes.CODE_FUNCTION]: 'sendAccountSwitchRequest',
        [SemanticAttributes.CODE_FILEPATH]:
          'src/context/data/AccountManagerProvider.js',
        ref_url: document.referrer,
        'app.tracking_id': trackingId,
      },
      payload: JSON.stringify(data),
      url: `${navigationSaUrl}account-selection`,
    });

    const url = '/myaccount/api/account/switchaccount';
    const options = {
      withCredentials: hasComAuthCore ? false : true,
      headers: { ...getTrackingIdHeader() },
    };
    const myAccountSwitchRequest = {
      method: 'post',
      data: {
        AccountNumber: accountNumber || orderId,
        AccountAuthGuid: authGuid || orderId,
        PageName: '',
        PhoneNumber: '',
      },
      url,
      key: 'bcpSwitch',
    };

    const myAccountSwitchCall = () =>
      spanRequest({
        method: myAccountSwitchRequest.method,
        requestFn: () => fido.fetch(myAccountSwitchRequest, options),
        tracerName: getTracerName('myAccountSwitchCall'),
        attributes: {
          [SemanticAttributes.CODE_FUNCTION]: 'sendAccountSwitchRequest',
          [SemanticAttributes.CODE_FILEPATH]:
            'src/context/data/AccountManagerProvider.js',
          ref_url: document.referrer,
          'app.tracking_id': trackingId,
        },
        payload: JSON.stringify(myAccountSwitchRequest.data),
        url: `${window.location.origin}${url}`,
      });

    return isBcpSwitchAccountCallDisabled
      ? // keeping this as Promise.all for now
        // to prevent differences in the type of response
        Promise.all([saSwitchCall])
      : Promise.all([saSwitchCall, myAccountSwitchCall()]);
  };

  const switchAccount = async (account, { withNavigate = false } = {}) => {
    // if we are using mock data - do mock switch
    if (account.mock) {
      setCurrentAccountId(account.authGuid);
      return;
    }

    setIsLoading(true);

    try {
      const {
        authGuid,
        orderId,
        isPreAccount,
        url: navigateUrl,
        newWindow,
      } = account;

      const [saSwitchResponse, myAccountSwitchResponse] =
        await sendAccountSwitchRequest(account);

      //do not fail for myAccount failures only SA failures
      if (saSwitchResponse.httpStatus === 200) {
        if (withNavigate) {
          window.open(
            navigateUrl,
            newWindow ? '_blank' : '_self',
            'noopener noreferrer'
          );

          // Avoid location reload if navigating within the same window
          if (!newWindow) {
            return;
          }
        }

        if (getScriptDataset().applicationType === APP_TYPES.SERVER) {
          location.reload();
          return;
        }
        setCurrentAccountId(account.authGuid || account.orderId);

        const eventData =
          orderId && isPreAccount ? { orderId } : { accountGuid: authGuid };
        dispatchEvent('Nav-Account-Switch', eventData);
        dispatchEvent('bsd-nav-close-hub-menu');
      } else if (
        myAccountSwitchResponse?.status === 401 ||
        myAccountSwitchResponse?.status === 403
      ) {
        // redirect to cima login page when switch account call returns forbidden
        window.location.assign(getConfig().cimaConfig.cimaLoginUrl);
      } else {
        trackEvent(
          events.ACCOUNT_SWITCH_ERROR(
            API_ERROR_MESSAGE(
              API_CALL_TYPE.switchAccount,
              saSwitchResponse.httpStatus
            )
          )
        );
      }

      setIsLoading(false);
    } catch (error) {
      trackEvent(events.ACCOUNT_SWITCH_ERROR(error.message));
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleAccountSwitchWithNavigate = (account) =>
    switchAccount(account, { withNavigate: true });

  const handleAccountSwitch = (account) => switchAccount(account);

  return (
    <AccountManagerContext.Provider
      value={{
        currentAccountId,
        currentAccountIsOrion,
        isLoading,
        handleSetCurrentAccount,
        handleAccountSwitch,
        handleAccountSwitchWithNavigate,
      }}
    >
      {children}
    </AccountManagerContext.Provider>
  );
};
export const useAccountManagerContext = () => useContext(AccountManagerContext);

AccountManagerProvider.propTypes = {
  children: PropTypes.node,
};
