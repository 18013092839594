/* eslint-disable react/sort-prop-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import {
  APP_NAMES,
  APP_PATHS,
  APP_TYPES,
  DESKTOP_QUERY,
  BROWSER_STORAGE_KEYS,
} from 'utils/constants';
import FirstTimeUserExperience from 'components/FirstTimeUserExperience/FirstTimeUserExperience';
import { L0, L1, L2, useMediaQuery } from 'bsd-global-nav-design-ui';

import { DataContext } from 'context/data/DataContextProvider';
import { AccountManagerContext } from 'context/data/AccountManagerProvider';

import { normalizeUrl } from 'utils/content';
import { syncCurrentAccountByAppType } from 'utils/applicationType';
import {
  getAppName,
  getDisabledSearchValue,
  getScriptDataset,
} from 'utils/script';
import { on, off, dispatchEvent } from 'utils/events';
import {
  getCurrentPath,
  getLogoUrl,
  getSearchUrl,
  getBrowserStorageWithExpiry,
  getTrackingId,
} from 'utils/util';
import { BaseContentItem, L1Data, ProfileData } from 'utils/shapes';

export default function GlobalHeader({
  hubData,
  l0Data,
  l1Data,
  l2Data,
  phoneData,
  profileData,
}) {
  const {
    header,
    featureFlags,
    ftue: ftueContent,
    isAuthenticated,
    isMegaMenu,
    userData,
    userDataReady,
    scriptData,
  } = useContext(DataContext);

  const { currentAccountId, handleAccountSwitch } = useContext(
    AccountManagerContext
  );

  const [l2InjectedItem, setL2InjectedItem] = useState();
  const isDesktop = useMediaQuery(DESKTOP_QUERY);

  const [runTour, setRunTour] = useState(false);
  const [processingMode, setProcessingMode] = useState('');
  const [ftueSteps, setFtueSteps] = useState([]);

  const { comcastLogoLink: logoLinks, searchUrl: searchUrls } =
    header.level_1_Navigation;

  const logoUrl = getLogoUrl(
    getAppName(),
    isAuthenticated,
    logoLinks,
    scriptData
  );

  const searchUrl =
    searchUrls && getSearchUrl(getAppName(), searchUrls, featureFlags);

  const initialRender = useRef(true);

  const disableSearch = getDisabledSearchValue();

  const handleLaunchFtue = useCallback(
    (event) => {
      const stepsForFtue = event?.detail?.ftueSteps || ftueContent;
      const showAutoPay = Boolean(event?.detail?.showAutoPay);
      const mode = event?.detail?.processingMode || 'Nav';

      setFtueSteps(
        stepsForFtue.filter(
          (step) =>
            step.displayCondition?.showAutoPay === undefined ||
            step.displayCondition?.showAutoPay === showAutoPay
        )
      );
      setProcessingMode(mode);
      setRunTour(true);
    },
    [ftueContent]
  );

  useEffect(() => {
    return () => {
      off('launch-ftue', handleLaunchFtue);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: when nav is integrated and we get rid of consumer app, this should go away as we will stop watching script changes
  // so injected item will just be directly set from script attribute and no state will be required for that
  useEffect(() => {
    // L2 injected item script attribute format:
    // data-placement-l2-right='[{"id":"bsd-nav-help-me-decide","placeHolderMarkup": "<b>Help Me Decide</b>"}]'
    setL2InjectedItem(
      scriptData?.placementL2Right
        ? JSON.parse(scriptData?.placementL2Right)?.[0] || null
        : null
    );
  }, [scriptData?.placementL2Right]);

  useEffect(() => {
    // dispatching custom event to let client apps know the global nav has been fully loaded and rendered
    // l2Data and profileData are used as markers, because their initial value is null
    if (initialRender.current) {
      const isNavReady =
        (!isAuthenticated && l2Data) ||
        (isAuthenticated && profileData && userDataReady);

      if (isNavReady) {
        const {
          firstTimeUserExperienceEnabled,
          hubFirstTimeUserExperienceEnabled,
        } = userData || {};
        const ftueEnabled = Boolean(firstTimeUserExperienceEnabled);
        const hubFtueEnabled = Boolean(hubFirstTimeUserExperienceEnabled);
        window.__GlobalNav = {
          firstTimeUserExperienceEnabled: ftueEnabled,
          hubFirstTimeUserExperienceEnabled: hubFtueEnabled,
          isAuthenticated,
          applicationName: getAppName(),
          loaded: true,
          isMegaMenu,
        };

        const isFirstTime = window.__GlobalNav?.firstTimeUserExperienceEnabled;
        const isCustomerHub = getAppName() === APP_NAMES.CustomerHub;
        const isAccountOverview =
          normalizeUrl(getCurrentPath()) === APP_PATHS.accountOverview;
        // this code should be removed when account overview is deprecated (it'll be replaced by customer hub)
        if (isFirstTime && isAccountOverview) {
          handleLaunchFtue();
        }

        if (isFirstTime || isCustomerHub || isAccountOverview) {
          on('launch-ftue', handleLaunchFtue);
        }

        const eventData = {
          applicationName: getAppName(),
          isAuthenticated: isAuthenticated,
          firstTimeUserExperienceEnabled: ftueEnabled,
          isMegaMenu,
        };

        if (
          isAuthenticated &&
          getScriptDataset().applicationType === APP_TYPES.SPA
        ) {
          if (
            !getBrowserStorageWithExpiry(
              BROWSER_STORAGE_KEYS.currentAccount,
              getTrackingId()
            )
          ) {
            // if no local storage value exists, it must first be created, then added to the event
            syncCurrentAccountByAppType(
              currentAccountId,
              userData,
              handleAccountSwitch
            );
          }

          eventData.currentAccount = JSON.parse(
            getBrowserStorageWithExpiry(
              BROWSER_STORAGE_KEYS.currentAccount,
              getTrackingId()
            )
          );
        }
        dispatchEvent('Global-Nav-Rendered', eventData);

        initialRender.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReady, l2Data, profileData, isAuthenticated, isMegaMenu]);

  return (
    /*we need id="bcp-header" and bsp-header-topbar class for apps that use those attributes to hide global header on Flow pages*/
    <>
      <header className="bsd-nav-header bsp-header-topbar" id="bcp-header">
        {isDesktop && <L0 l0Data={l0Data} />}
        <L1
          appRootPath={logoUrl}
          currentAccountGuid={currentAccountId}
          data={l1Data}
          disableSearch={disableSearch}
          enableMegaMenu={isMegaMenu}
          hubData={hubData}
          isAuthenticated={isAuthenticated}
          l0Data={l0Data}
          l2InjectedItem={l2InjectedItem}
          onAccountSwitch={handleAccountSwitch}
          phoneData={phoneData}
          profileData={profileData}
          searchUrl={searchUrl}
          userData={userData}
        />
        {isDesktop && !isMegaMenu && (
          <L2 data={l2Data} injectedItem={l2InjectedItem} />
        )}
      </header>

      <FirstTimeUserExperience
        processingMode={processingMode}
        profileData={profileData}
        runTour={runTour}
        setRunTour={setRunTour}
        steps={ftueSteps}
      />
    </>
  );
}

GlobalHeader.propTypes = {
  hubData: PropTypes.object,
  l0Data: PropTypes.arrayOf(BaseContentItem),
  l1Data: L1Data,
  l2Data: PropTypes.arrayOf(BaseContentItem),
  phoneData: PropTypes.shape({
    phoneNumber: PropTypes.string,
  }),
  profileData: ProfileData,
  onNavReady: PropTypes.func,
  setFTUEProfileData: PropTypes.func,
};
