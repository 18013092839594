import { APP_NAMES } from './constants';

export const getScriptTag = () => document.querySelector('#bsd-global-nav');

export const getScriptDataset = () => {
  const script = getScriptTag();

  if (!script?.dataset) {
    return {};
  }

  const { authToken } = script.dataset;

  if (authToken) {
    return {
      ...script.dataset,
      authToken: authToken.match(/^Bearer /)
        ? authToken
        : `Bearer ${authToken}`,
    };
  }

  return script?.dataset;
};

export const getAppName = () => getScriptDataset().applicationName;

export const getAppRootPath = () => getScriptDataset().applicationRootPath;

export const getMockPathName = () => getScriptDataset().applicationMockPathname;

export const getComAuthCore = () => getScriptDataset().comAuthCore;

export const getAuthGuidId = () => getScriptDataset().initialAccount;

export const getOrderId = () => getScriptDataset().initialOrder;

export const getDisabledSearchValue = () =>
  getScriptDataset().disableSearch === 'true';

export const getOverrideSignInUrl = () => getScriptDataset().overrideSignInUrl;

export const getIsEnterprise = () => getScriptDataset().isEnterprise === 'true';

/**
 * Function used to retrieve initial component data from the script tag and filter based on type
 * @param {string} type - flag to determine desired component type - header, footer ect
 */
const getComponent = (type) => {
  const navComponents = JSON.parse(getScriptDataset().components);

  return navComponents.find((x) => x.type === type);
};

export const getHeaderComponent = () =>
  getComponent('GLOBAL_HEADER') || getComponent('FLOW_HEADER');

export const getFooterComponent = () =>
  getComponent('GLOBAL_FOOTER') || getComponent('FLOW_FOOTER');

export const ignoreL2 = () => {
  const appName = getAppName();
  const { hideL2 } = getScriptDataset();

  const appsWithL2 = [
    APP_NAMES.BSEE,
    APP_NAMES.MobileBuy,
    APP_NAMES.MobileLearn,
  ];

  return !appsWithL2.includes(appName) || hideL2 === 'true';
};

export const ignoreSelectedStates = () => {
  const { ignoreSelectedStates } = getScriptDataset();

  return (
    [APP_NAMES.Activecore].includes(getAppName()) ||
    ignoreSelectedStates === 'true'
  );
};
