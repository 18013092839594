/**
 * Function used to merge nav content with mega menu content including feature flags
 * @param navContent - response containing only nav related content
 * @param megaMenuContent - response containing only mega menu content, essentially l0 and level1_left
 * @returns {*&{components: (*&{globalNavigation: (*&{navigation: (*&{megamenu: (*&{level_1_Navigation: (*&{level1_left: *[]}), level_0_Navigation: *[]})})})}), featureFlags: (*)}}
 */
export const performMegaMenuMerge = (navContent, megaMenuContent) => {
  const { featureFlags } = navContent || {};
  const { featureFlags: megaMenuFlags } = megaMenuContent || {};
  const { header } = navContent.components.globalNavigation.navigation;
  const { megamenu } = megaMenuContent.components.megaMenuNavigation.navigation;

  return {
    ...navContent,
    components: {
      ...navContent.components,
      globalNavigation: {
        ...navContent.components.globalNavigation,
        navigation: {
          ...navContent.components.globalNavigation.navigation,
          megamenu: {
            ...header,
            level_0_Navigation: [...megamenu.level_0_Navigation],
            level_1_Navigation: {
              ...header.level_1_Navigation,
              level1_left: [
                ...megamenu.level_1_Navigation.level1_left,
                ...header.level_1_Navigation.level1_left,
              ].filter((x) =>
                x.DisplayText === 'Support' ||
                x.DisplayText === 'My Account' ||
                x.DisplayText === 'Public Sector'
                  ? true
                  : x.DisplayCondition.containsMegamenu
              ),
            },
          },
        },
      },
    },
    featureFlags: {
      ...featureFlags,
      ...megaMenuFlags,
    },
  };
};
