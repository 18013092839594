const cartUrl = 'https://dev.cart.ecom.np.digital.business.comcast.com/cart.js';
const cimaLoginUrl = 'https://business.dev.comcast.com/account/';
const contentUrl =
  'https://global-nav-content-dev.np.digital.business.comcast.com/content.json';
const megaMenuContentUrl =
  'https://mega-menu-content-dev.np.digital.business.comcast.com/content.json';
const navigationSaUrl =
  'https://dev.web1.self-service.np.digital.business.comcast.com/account/ss-service-navigation/';
const navSaRetryLimit = 4;
const defaultRetryMillis = 10000;

export default {
  cartConfig: {
    cartUrl,
  },
  cimaConfig: {
    cimaLoginUrl,
  },
  contentConfig: {
    contentUrl,
    megaMenuContentUrl,
  },
  isProductionEnvironment: false,
  navSaConfig: {
    defaultRetryMillis,
    navigationSaUrl,
    navSaRetryLimit,
  },
  fidoConfig: {
    headers: {
      'tracking-id': '',
      'x-api-key': 'tgnvhW9jET8J9pQT3iET36M3DvVYRgCw5EbBwSjo',
    },
    dataApiConfig: {
      bcpSwitch: {
        baseUrl: 'https://business.dev.comcast.com/',
      },
    },
  },
  applicationId: 'ss-global-nav',
  featureFlags: {
    isPhoneIconAndNumberHidden: false,
  },
};
