import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HeaderWrapper from 'components/templates/Header/HeaderWrapper';
import FooterWrapper from 'components/templates/Footer/FooterWrapper';
import { Spinner } from 'bsd-global-nav-design-ui';
import { DataContext } from 'context/data/DataContextProvider';
import { AccountManagerContext } from 'context/data/AccountManagerProvider';
import { NavigationAccountBillerType } from '@bsd/service-agent-selfservice-service-navigation-api';

import { EVENT_TYPES } from 'utils/constants';
import { off, on } from 'utils/events';
import {
  getFooterComponent,
  getHeaderComponent,
  getOverrideSignInUrl,
  ignoreL2,
  ignoreSelectedStates,
} from 'utils/script';
import { applyRules } from 'utils/displayRules';
import {
  containsL2,
  containsL2OrMegaMenu,
  getRelevantL1ForL0,
  isSelected,
  setL0SelectedNavItems,
  setSelectedNavItems,
} from 'utils/content';
import {
  buildProfileItems,
  customizeL1Items,
  getCustomizedL1RightItems,
  getCurrentAccount,
  getCurrentPath,
  getFooterModifier,
  getPhoneData,
} from 'utils/util';
import ErrorBoundary from 'components/Error/ErrorBoundary';

export default function GlobalWrapper({ headerComponent, footerComponent }) {
  const {
    contentReady,
    featureFlags,
    header,
    isAuthenticated,
    scriptData,
    userData,
    isMegaMenu,
  } = useContext(DataContext);

  const {
    currentAccountId,
    currentAccountIsOrion,
    handleAccountSwitchWithNavigate,
    isLoading,
  } = useContext(AccountManagerContext);

  // eslint-disable-next-line no-unused-vars
  const [currentL0, setCurrentL0] = useState('smallBusiness');
  const [footerL2Data, setFooterL2Data] = useState(null);
  const [l0Data, setL0Data] = useState(header.level_0_Navigation);
  const [l1Data, setL1Data] = useState(header.level_1_Navigation);
  const [l2Data, setL2Data] = useState(null);
  const [hubData, setHubData] = useState(null);
  const [phoneData, setPhoneData] = useState(null);
  const [profileData, setProfileData] = useState(null);

  const { level1_right: l1Right } = header.level_1_Navigation;

  const skipRules =
    getHeaderComponent()?.type === 'FLOW_HEADER' &&
    getFooterComponent()?.type === 'FLOW_FOOTER';

  const onSwitchWithNavigate = useCallback((e) => {
    const { detail = {} } = e || {};

    const hasUrl = typeof detail.url === 'string';

    if (hasUrl) {
      handleAccountSwitchWithNavigate(detail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    on(EVENT_TYPES.accountSwitchWithNavigate, onSwitchWithNavigate);

    return () => {
      off(EVENT_TYPES.accountSwitchWithNavigate, onSwitchWithNavigate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unAuthReady = !isAuthenticated && contentReady;
    // TODO change this to account for userDataReady as well if we need to set selected states to Hub
    const authReady = isAuthenticated && contentReady;
    const isReady = unAuthReady || authReady;

    if (!skipRules && isReady) {
      const { level_0_Navigation: l0Data, level_1_Navigation: l1Data } = header;
      const customizedL0 = JSON.parse(JSON.stringify(l0Data));
      const customizedL1 = JSON.parse(JSON.stringify(l1Data));

      // remove L2 if not needed
      if (ignoreL2()) {
        // Need to grab L2 items before they are removed, so they can be rendered in the footer when l2 is hidden
        setFooterL2Data(
          customizedL1.level1_left
            .filter((item) => containsL2OrMegaMenu(item, isMegaMenu))
            ?.map((item) => {
              return { ...item };
            })
        );

        customizedL1.level1_left.forEach((l1Item) => {
          if (containsL2(l1Item)) {
            l1Item.children = [];
          }
        });
      }

      const currentPath = getCurrentPath();

      // setting L0 with selected states
      setL0SelectedNavItems(currentPath, customizedL0, isMegaMenu);
      setL0Data(customizedL0);

      // setting L1 with selected states
      const currentL0 = customizedL0.find((item) => isSelected(item));
      setCurrentL0(currentL0.DisplayId);
      const currentL1 = getRelevantL1ForL0(customizedL1.level1_left, currentL0);
      if (!ignoreSelectedStates()) {
        setSelectedNavItems(currentPath, currentL1, isMegaMenu);
      }
      customizedL1.level1_left = customizeL1Items(
        currentL1,
        null,
        null,
        featureFlags
      );

      customizedL1.level1_right = getCustomizedL1RightItems(
        customizedL1.level1_right,
        featureFlags
      );

      if (!isAuthenticated && getOverrideSignInUrl()) {
        const item = customizedL1.level1_right?.unauthenticated?.find(
          (item) => item.userText === 'Sign In'
        );
        item.DisplayLink = getOverrideSignInUrl();
      }

      setL1Data(customizedL1);

      // setting L2
      const l1ItemWithL2Children = customizedL1.level1_left.find(
        (item) => containsL2(item) && isSelected(item)
      );
      setL2Data(l1ItemWithL2Children?.children || []);

      setPhoneData(getPhoneData(isAuthenticated, false, l1Right));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentReady, skipRules]);

  useEffect(() => {
    if (!skipRules && contentReady && currentAccountId) {
      // run hub menu through rules
      const { hub_Navigation: initialHubNav, defaultAccountUrl } =
        header.hub_MenuPanel;

      const isCsg = userData?.accounts?.some(
        (acc) =>
          acc?.accountBillerType ===
          NavigationAccountBillerType.CableServiceGroup
      );

      const accountData = {
        ...getCurrentAccount(userData?.accounts, currentAccountId),
        isCsg,
        userServices: userData?.services,
      };

      const customizedHubData = applyRules(
        initialHubNav,
        accountData,
        featureFlags
      );
      setHubData({
        hub_Navigation: customizedHubData,
        defaultAccountUrl,
      });

      const {
        authenticated: [, userContextData],
      } = l1Right;
      const profileItems = userContextData?.userContext?.myProfile?.children;
      const updatedProfileItems = buildProfileItems(
        profileItems,
        accountData,
        scriptData?.overrideSignOutUrl,
        featureFlags
      );

      const profileData = {
        children: updatedProfileItems,
      };

      setProfileData(profileData);

      // Filtering Support items based on available services
      const { level_1_Navigation: l1DataInitial } = header;
      // need initial array of Support menu children to filter from in case we are switching account without page reload
      const initialSupportChildren =
        l1DataInitial.level1_left.find((item) => item.DisplayText === 'Support')
          ?.children || [];

      setL1Data((l1Data) => ({
        ...l1Data,
        level1_left: customizeL1Items(
          l1Data.level1_left,
          accountData,
          initialSupportChildren,
          featureFlags
        ),
        level1_right: getCustomizedL1RightItems(
          l1Data.level1_right,
          featureFlags
        ),
      }));

      // TODO in case we need selected states for Hub menu:
      // 1) apply selected states to Hub items here
      // 2) when nav is ready (see last useEffect in GlobalHeader) attach listener with selected states functionality to NAV_ROUTE_CHANGE_EVENT
      // (use ref to preserve the reference to handler for later removal)
      // 3) add listener removal on unmount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentReady, currentAccountId, userData]);

  useEffect(() => {
    if (userData) {
      setPhoneData(
        getPhoneData(isAuthenticated, currentAccountIsOrion, l1Right)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      {isLoading && <Spinner />}
      {headerComponent && (
        <ErrorBoundary>
          <HeaderWrapper
            headerComponent={headerComponent}
            hubData={hubData}
            l0Data={l0Data}
            l1Data={l1Data}
            l2Data={l2Data}
            phoneData={phoneData}
            profileData={profileData}
          />
        </ErrorBoundary>
      )}
      {footerComponent && contentReady && (
        <ErrorBoundary>
          <FooterWrapper
            footerComponent={footerComponent}
            footerL1Data={l1Data}
            footerL2Data={footerL2Data}
            footerModifier={getFooterModifier()}
            hubData={hubData}
          />
        </ErrorBoundary>
      )}
    </>
  );
}

GlobalWrapper.propTypes = {
  footerComponent: PropTypes.object,
  headerComponent: PropTypes.object,
};
